export enum Command {
  ADD_NEW_CHAPTER_TO_GUIDE = 'ADD_NEW_CHAPTER_TO_GUIDE',
  ADD_NEW_IMAGE_WIDGET_TO_SECTION = 'ADD_NEW_IMAGE_WIDGET_TO_SECTION',
  ADD_NEW_VIDEO_WIDGET_TO_SECTION = 'ADD_NEW_VIDEO_WIDGET_TO_SECTION',
  ADD_NEW_PAGE_TO_CHAPTER = 'ADD_NEW_PAGE_TO_CHAPTER',
  ADD_NEW_SECTION_TO_PAGE = 'ADD_NEW_SECTION_TO_PAGE',
  ADD_NEW_TEXT_WIDGET_TO_SECTION = 'ADD_NEW_TEXT_WIDGET_TO_SECTION',
  ADD_NEW_TITLE_WIDGET_TO_SECTION = 'ADD_NEW_TITLE_WIDGET_TO_SECTION',
  ADD_NEW_CARD_WIDGET_TO_SECTION = 'ADD_NEW_CARD_WIDGET_TO_SECTION',
  CHANGE_SECTION_LAYOUT = 'CHANGE_SECTION_LAYOUT',
  MOVE_WIDGET = 'MOVE_WIDGET',
  CREATE_GUIDE = 'CREATE_GUIDE',
  DELETE_CHAPTER_FROM_GUIDE = 'DELETE_CHAPTER_FROM_GUIDE',
  DELETE_GUIDE = 'DELETE_GUIDE',
  DELETE_PAGE_FROM_CHAPTER = 'DELETE_PAGE_FROM_CHAPTER',
  DELETE_SECTION_FROM_PAGE = 'DELETE_SECTION_FROM_PAGE',
  DELETE_WIDGET_FROM_SECTION = 'DELETE_WIDGET_FROM_SECTION',
  EDIT_CHAPTER_TITLE = 'EDIT_CHAPTER_TITLE',
  EDIT_GUIDE_TITLE = 'EDIT_GUIDE_TITLE',
  EDIT_PAGE_TITLE = 'EDIT_PAGE_TITLE',
  EDIT_TEXT_IN_TEXT_WIDGET = 'EDIT_TEXT_IN_TEXT_WIDGET',
  EDIT_TITLE_IN_TITLE_WIDGET = 'EDIT_TITLE_IN_TITLE_WIDGET',
  PLACE_ASSET_IN_IMAGE_WIDGET = 'PLACE_ASSET_IN_IMAGE_WIDGET',
  PLACE_ASSET_IN_VIDEO_WIDGET = 'PLACE_ASSET_IN_VIDEO_WIDGET',
  SET_GUIDE_COVER = 'SET_GUIDE_COVER',
  REMOVE_GUIDE_COVER = 'REMOVE_GUIDE_COVER',
  MOVE_PAGE = 'MOVE_PAGE',
  MOVE_CHAPTER = 'MOVE_CHAPTER',
  ADD_NEW_COLOR_PALETTE_WIDGET_TO_SECTION = 'ADD_NEW_COLOR_PALETTE_WIDGET_TO_SECTION',
  ADD_NEW_COLOR_TO_COLOR_PALETTE_WIDGET = 'ADD_NEW_COLOR_TO_COLOR_PALETTE_WIDGET',
  REMOVE_COLOR_FROM_COLOR_PALETTE_WIDGET = 'REMOVE_COLOR_FROM_COLOR_PALETTE_WIDGET',
  PUBLISH_PAGE = 'PUBLISH_PAGE',
  UNPUBLISH_PAGE = 'UNPUBLISH_PAGE',
  PLACE_ASSET_IN_PAGE_HEADER = 'PLACE_ASSET_IN_PAGE_HEADER',
  REMOVE_ASSET_FROM_PAGE_HEADER = 'REMOVE_ASSET_FROM_PAGE_HEADER',
  EDIT_BACKGROUND_COLOR_IN_PAGE_HEADER = 'EDIT_BACKGROUND_COLOR_IN_PAGE_HEADER',
  EDIT_TEXT_COLOR_IN_PAGE_HEADER = 'EDIT_TEXT_COLOR_IN_PAGE_HEADER',
  EDIT_HEIGHT_IN_PAGE_HEADER = 'EDIT_HEIGHT_IN_PAGE_HEADER',
  EDIT_GUIDE_ACCESS = 'EDIT_GUIDE_ACCESS',
  ADD_NEW_COLLECTION_WIDGET_TO_SECTION = 'ADD_NEW_COLLECTION_WIDGET_TO_SECTION',
  ADD_NEW_SPACING_WIDGET_TO_SECTION = 'ADD_NEW_SPACING_WIDGET_TO_SECTION',
  EDIT_HEIGHT_IN_SPACING_WIDGET = 'EDIT_HEIGHT_IN_SPACING_WIDGET',
  ALLOW_ALL_PORTAL_USERS_TO_VIEW_GUIDE = 'ALLOW_ALL_PORTAL_USERS_TO_VIEW_GUIDE',
  MAKE_GUIDE_PUBLIC = 'MAKE_GUIDE_PUBLIC',
  EDIT_COLOR_IN_COLOR_PALETTE_WIDGET = 'EDIT_COLOR_IN_COLOR_PALETTE_WIDGET',
  ADD_NEW_TABLE_WIDGET_TO_SECTION = 'ADD_NEW_TABLE_WIDGET_TO_SECTION',
  EDIT_CELLS_CONTENT_IN_TABLE_WIDGET = 'EDIT_CELLS_CONTENT_IN_TABLE_WIDGET',
  INSERT_ROW_IN_TABLE_WIDGET = 'INSERT_ROW_IN_TABLE_WIDGET',
  INSERT_COLUMN_IN_TABLE_WIDGET = 'INSERT_COLUMN_IN_TABLE_WIDGET',
  REMOVE_COLUMN_FROM_TABLE_WIDGET = 'REMOVE_COLUMN_FROM_TABLE_WIDGET',
  REMOVE_ROW_FROM_TABLE_WIDGET = 'REMOVE_ROW_FROM_TABLE_WIDGET',
  ADD_NEW_AUDIO_WIDGET_TO_SECTION = 'ADD_NEW_AUDIO_WIDGET_TO_SECTION',
  PLACE_ASSET_IN_AUDIO_WIDGET = 'PLACE_ASSET_IN_AUDIO_WIDGET',
  FEATURE_GUIDE = 'FEATURE_GUIDE',
  UNFEATURE_GUIDE = 'UNFEATURE_GUIDE',
  EDIT_COLLECTION_WIDGET = 'EDIT_COLLECTION_WIDGET',
  SET_DEFAULT_COLOR_CODES_IN_COLOR_PALETTE_WIDGET = 'SET_DEFAULT_COLOR_CODES_IN_COLOR_PALETTE_WIDGET',
  MOVE_SECTION = 'MOVE_SECTION',
  DUPLICATE_SECTION_IN_PAGE = 'DUPLICATE_SECTION_IN_PAGE',
  EDIT_BACKGROUND_COLOR_IN_SECTION = 'EDIT_BACKGROUND_COLOR_IN_SECTION',
  EDIT_TABLE_OF_CONTENTS = 'EDIT_TABLE_OF_CONTENTS',
  EDIT_DIVIDER_IN_SPACING_WIDGET = 'EDIT_DIVIDER_IN_SPACING_WIDGET',
  CREATE_GROUP = 'CREATE_GROUP',
  ADD_GUIDE_TO_GROUP = 'ADD_GUIDE_TO_GROUP',
  EDIT_GROUP_TITLE = 'EDIT_GROUP_TITLE',
  EDIT_GROUP_DESCRIPTION = 'EDIT_GROUP_DESCRIPTION',
  EDIT_GROUP_COVER_ASSET = 'EDIT_GROUP_COVER_ASSET',
  EDIT_GROUP_COVER_COLOR = 'EDIT_GROUP_COVER_COLOR',
  DELETE_GUIDE_FROM_GROUP = 'DELETE_GUIDE_FROM_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  SET_IMAGE_ACTION_TO_OPEN_PREVIEW = 'SET_IMAGE_ACTION_TO_OPEN_PREVIEW',
  SET_IMAGE_ACTION_TO_NO_ACTION = 'SET_IMAGE_ACTION_TO_NO_ACTION',
  SET_IMAGE_ACTION_TO_LINK_TO_URL = 'SET_IMAGE_ACTION_TO_LINK_TO_URL',
  CREATE_GUIDE_INSIDE_GROUP = 'CREATE_GUIDE_INSIDE_GROUP',
  EDIT_GROUP_ACCESS = 'EDIT_GROUP_ACCESS',
  EDIT_PAGE_ACCESS = 'EDIT_PAGE_ACCESS',
  EDIT_CHAPTER_ACCESS = 'EDIT_CHAPTER_ACCESS',
  CREATE_GUIDE_WITH_TEMPLATE = 'CREATE_GUIDE_WITH_TEMPLATE',
  CREATE_GUIDE_WITH_TEMPLATE_INSIDE_GROUP = 'CREATE_GUIDE_WITH_TEMPLATE_INSIDE_GROUP',
  DUPLICATE_GUIDE = 'DUPLICATE_GUIDE',
  DUPLICATE_GUIDE_INSIDE_GROUP = 'DUPLICATE_GUIDE_INSIDE_GROUP',
  MOVE_PAGE_TO_GUIDE = 'MOVE_PAGE_TO_GUIDE',
  DELETE_GUIDE_INSIDE_GROUP = 'DELETE_GUIDE_INSIDE_GROUP',
  EDIT_SECTION_WIDTH = 'EDIT_SECTION_WIDTH',
  EDIT_SECTION_HEIGHT = 'EDIT_SECTION_HEIGHT',
  EDIT_SECTION_CONTENT_ALIGNMENT = 'EDIT_SECTION_CONTENT_ALIGNMENT',
  EDIT_SECTION_CONTENT_SPACING = 'EDIT_SECTION_CONTENT_SPACING',
  EDIT_SECTION_HORIZONTAL_PADDING = 'EDIT_SECTION_HORIZONTAL_PADDING',
  EDIT_SECTION_VERTICAL_PADDING = 'EDIT_SECTION_VERTICAL_PADDING',
  EDIT_TITLE_VISIBILITY_IN_PAGE_HEADER = 'EDIT_TITLE_VISIBILITY_IN_PAGE_HEADER',
  EDIT_SUBTITLE_VISIBILITY_IN_PAGE_HEADER = 'EDIT_SUBTITLE_VISIBILITY_IN_PAGE_HEADER',
  EDIT_SECTION_COLUMN_SPACING = 'EDIT_SECTION_COLUMN_SPACING',
  EDIT_LOOP_IN_VIDEO_WIDGET = 'EDIT_LOOP_IN_VIDEO_WIDGET',
  EDIT_AUTOPLAY_IN_VIDEO_WIDGET = 'EDIT_AUTOPLAY_IN_VIDEO_WIDGET',
  EDIT_CONTROLS_IN_VIDEO_WIDGET = 'EDIT_CONTROLS_IN_VIDEO_WIDGET',
  EDIT_MUTED_IN_VIDEO_WIDGET = 'EDIT_MUTED_IN_VIDEO_WIDGET',
  PLACE_ASSET_IN_SECTION = 'PLACE_ASSET_IN_SECTION',
  REMOVE_ASSET_FROM_SECTION = 'REMOVE_ASSET_FROM_SECTION',
  EDIT_HEADER_VISIBILITY_IN_PAGE_HEADER = 'EDIT_HEADER_VISIBILITY_IN_PAGE_HEADER',
  ADD_NEW_BUTTON_WIDGET_TO_SECTION = 'ADD_NEW_BUTTON_WIDGET_TO_SECTION',
  ADD_NEW_BUTTONS_TO_BUTTON_WIDGET = 'ADD_NEW_BUTTONS_TO_BUTTON_WIDGET',
  EDIT_NAME_IN_BUTTON_WIDGET = 'EDIT_NAME_IN_BUTTON_WIDGET',
  EDIT_LINK_IN_BUTTON_WIDGET = 'EDIT_LINK_IN_BUTTON_WIDGET',
  EDIT_STYLE_IN_BUTTON_WIDGET = 'EDIT_STYLE_IN_BUTTON_WIDGET',
  EDIT_WIDGET_STYLE_IN_BUTTON_WIDGET = 'EDIT_WIDGET_STYLE_IN_BUTTON_WIDGET',
  REMOVE_BUTTON_FROM_BUTTON_WIDGET = 'REMOVE_BUTTON_FROM_BUTTON_WIDGET',
  MOVE_BUTTON_IN_BUTTON_WIDGET = 'MOVE_BUTTON_IN_BUTTON_WIDGET',
  EDIT_STYLE_IN_SECTION = 'EDIT_STYLE_IN_SECTION',
  ADD_NEW_CARDS_TO_CARD_WIDGET = 'ADD_NEW_CARDS_TO_CARD_WIDGET',
  REMOVE_CARD_FROM_CARD_WIDGET = 'REMOVE_CARD_FROM_CARD_WIDGET',
  EDIT_CARD_HEADLINE_IN_CARD_WIDGET = 'EDIT_CARD_HEADLINE_IN_CARD_WIDGET',
  EDIT_CARD_ASSET_IN_CARD_WIDGET = 'EDIT_CARD_ASSET_IN_CARD_WIDGET',
  EDIT_CARD_LINK_IN_CARD_WIDGET = 'EDIT_CARD_LINK_IN_CARD_WIDGET',
  EDIT_STYLE_IN_CARD_WIDGET = 'EDIT_STYLE_IN_CARD_WIDGET',
  EDIT_WIDGET_STYLE_IN_CARD_WIDGET = 'EDIT_WIDGET_STYLE_IN_CARD_WIDGET',
  MOVE_CARD_IN_CARD_WIDGET = 'MOVE_CARD_IN_CARD_WIDGET',
  EDIT_STYLE_IN_SPACING_WIDGET = 'EDIT_STYLE_IN_SPACING_WIDGET',
  EXECUTE_GUIDE_BATCH = 'EXECUTE_GUIDE_BATCH',
  ADD_NEW_YOUTUBE_WIDGET_TO_SECTION = 'ADD_NEW_YOUTUBE_WIDGET_TO_SECTION',
  EDIT_SOURCE_IN_YOUTUBE_WIDGET = 'EDIT_SOURCE_IN_YOUTUBE_WIDGET',
  EDIT_SETTINGS_IN_YOUTUBE_WIDGET = 'EDIT_SETTINGS_IN_YOUTUBE_WIDGET',
  ADD_NEW_VIMEO_WIDGET_TO_SECTION = 'ADD_NEW_VIMEO_WIDGET_TO_SECTION',
  EDIT_SOURCE_IN_VIMEO_WIDGET = 'EDIT_SOURCE_IN_VIMEO_WIDGET',
  EDIT_SETTINGS_IN_VIMEO_WIDGET = 'EDIT_SETTINGS_IN_VIMEO_WIDGET',
  EDIT_STYLE_IN_COLOR_PALETTE_WIDGET = 'EDIT_STYLE_IN_COLOR_PALETTE_WIDGET',
  EDIT_STYLE_IN_COLLECTION_WIDGET = 'EDIT_STYLE_IN_COLLECTION_WIDGET',
  ADD_NEW_FIGMA_WIDGET_TO_SECTION = 'ADD_NEW_FIGMA_WIDGET_TO_SECTION',
  EDIT_SOURCE_IN_FIGMA_WIDGET = 'EDIT_SOURCE_IN_FIGMA_WIDGET',
  EDIT_SETTINGS_IN_FIGMA_WIDGET = 'EDIT_SETTINGS_IN_FIGMA_WIDGET',
}
