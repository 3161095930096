import React from 'react';
import { IconFolder } from '@bynder/icons';
import {
  Button,
  Form,
  ModalBase,
  Select,
  Thumbnail,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import { useFetchGuideById, useGetOverviewGuides } from '../../queries/guides';
import { generateUUID } from '../../helpers/utils';
import { usePageMutations } from '../../mutations/pages';
import { useIsEntityChanging } from '../../stores/socketsStore';

export interface MovePageModalProps {
  onCancel: () => void;
  pageTitle: string;
  activeGuideId: string;
  activeGuideVersion: number;
  pageId: string;
  chapterId: string;
}

export const MovePageModal: React.FC<MovePageModalProps> = ({
  onCancel,
  pageTitle,
  activeGuideId,
  activeGuideVersion,
  pageId,
  chapterId,
}) => {
  const { translate } = useTranslate();
  const [searchGuideValue, setSearchGuideValue] = React.useState('');
  const [searchChapterValue, setSearchChapterValue] = React.useState('');
  const [selectedGuideId, setSelectedGuideId] = React.useState(activeGuideId);
  const [selectedChapterId, setSelectedChapterId] = React.useState(chapterId);
  const { duplicatePage } = usePageMutations();

  const isChangingEntity = useIsEntityChanging(activeGuideId);

  const { data: guides } = useGetOverviewGuides();

  const multiPageGuide = guides?.allGuides?.filter(
    guide => guide.type === 'MULTI_PAGE',
  );
  const { data: toGuide } = useFetchGuideById(selectedGuideId);

  React.useEffect(() => {
    setSelectedChapterId(toGuide?.chapters[0]?.id);
  }, [toGuide?.chapters]);

  const toChapter = toGuide?.chapters?.find(
    chapter => chapter.id === selectedChapterId,
  );

  const filteredGuides = multiPageGuide?.filter(item =>
    item.title.toLowerCase().includes(searchGuideValue.trim().toLowerCase()),
  );

  const filteredChapters = toGuide?.chapters.filter(item =>
    item.title.toLowerCase().includes(searchChapterValue.trim().toLowerCase()),
  );

  const handleSubmit = () => {
    const toPageId = generateUUID();

    duplicatePage.mutate({
      toGuideId: selectedGuideId,
      toChapterId: selectedChapterId,
      toPageId,
      toGuideVersion: toGuide?.version,
      guideTitle: toGuide?.title,
      legacyActiveChapterId: chapterId,
      legacyActiveGuideId: activeGuideId,
      legacyActivePageId: pageId,
      legacyActiveGuideVersion: activeGuideVersion,
    });
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header
        title={translate('MOVE_PAGE_MODAL.DEFAULT_HEADER_TEXT', { pageTitle })}
        secondaryInfo={translate('MOVE_PAGE_MODAL.DEFAULT_SUBTITLE_TEXT')}
      />
      <ModalBase.Content>
        <Form.Group>
          <Form.Label htmlFor="guideSelect">
            <Translate id="MOVE_PAGE_MODAL.GUIDE_INPUT_LABEL" />
          </Form.Label>
          <Form.Row>
            <Select
              aria-labelledby="guideSelect"
              data-testid="guideSelect"
              value={selectedGuideId}
              onChange={setSelectedGuideId}
              label={toGuide?.title}
              icon={
                <Thumbnail
                  variant="content"
                  size="xs"
                  imageUrl={toGuide?.cover?.imageUrl}
                />
              }
            >
              <Select.SearchInput
                value={searchGuideValue}
                onChange={setSearchGuideValue}
                placeholder="Search"
                aria-label="Search for options"
              />

              {filteredGuides?.map(guide => (
                <Select.Item
                  key={guide.id}
                  value={guide.id}
                  isDisabled={!guide.canEdit}
                  icon={
                    <Thumbnail
                      variant="content"
                      size="xs"
                      imageUrl={guide.cover?.imageUrl}
                    />
                  }
                >
                  {guide.title}
                </Select.Item>
              ))}
            </Select>
          </Form.Row>
          <Form.HelperText id="guideSelect">
            <Translate id="MOVE_PAGE_MODAL.GUIDE_INPUT_DESCRIPTION" />
          </Form.HelperText>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="chapterSelect">
            {translate('MOVE_PAGE_MODAL.CHAPTER_INPUT_LABEL')}
          </Form.Label>
          <Form.Row>
            <Select
              aria-labelledby="chapterSelect"
              data-testid="chapterSelect"
              value={selectedChapterId}
              onChange={setSelectedChapterId}
              label={toChapter?.title}
              icon={<IconFolder />}
            >
              <Select.SearchInput
                value={searchChapterValue}
                onChange={setSearchChapterValue}
                placeholder="Search"
                aria-label="Search for options"
              />
              {filteredChapters?.map(chapter => (
                <Select.Item
                  key={chapter.id}
                  value={chapter.id}
                  isDisabled={!chapter.canEdit}
                  icon={<IconFolder />}
                >
                  {chapter.title}
                </Select.Item>
              ))}
            </Select>
          </Form.Row>
        </Form.Group>
        {/* <Divider />
        <Form.Group>
          <Form.Row>
            <Switch
              isChecked={isSwitchChecked}
              isDisabled={!canDeletePage}
              onChange={() => {
                setIsSwitchChecked(!isSwitchChecked);
              }}
            >
              {translate('MOVE_PAGE_MODAL.SWITCH_LABEL')}
            </Switch>
          </Form.Row>
          {!canDeletePage && (
            <Form.HelperText id="guideSelect">
              <Translate id="MOVE_PAGE_MODAL.SWITCH_DESCRIPTION" />
            </Form.HelperText>
          )}
        </Form.Group> */}
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          isChangingEntity ? (
            <PrimaryButton data-testid="submitButton" isDisabled isLoading>
              <Translate id="MOVE_PAGE_MODAL.LOADING_BUTTON_TEXT" />
            </PrimaryButton>
          ) : (
            <PrimaryButton
              data-testid="submitButton"
              onClick={handleSubmit}
              isDisabled={!selectedChapterId?.length}
            >
              <Translate id="MOVE_PAGE_MODAL.DEFAULT_BUTTON_TEXT" />
            </PrimaryButton>
          )
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="MOVE_PAGE_MODAL.CANCEL_BUTTON_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};
