import {
  IconDelete,
  IconError,
  IconLink,
  IconWarning,
  IconUpload,
} from '@bynder/icons';
import { useHistory } from 'react-router-dom';
import { Button, Thumbnail, token } from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import { ROUTES } from '../constants/app';
import { NotificationActions } from '../constants/notifications';
import { useGuideStore } from '../stores/guideStore';

export interface NotificationInterface {
  readonly id?: string;
  readonly description?: React.ReactElement;
  readonly title: React.ReactElement;
  readonly thumbnail?: React.ReactElement;
  readonly button?: React.ReactElement;
  readonly isPersistent: boolean;
  readonly variant?: 'loading' | 'success' | 'error';
}

export const websocketOfflineId = 'ws-offline-notification';
export const timeoutNotificationId = 'timeout-notification';

const NotificationButton: React.FC<{
  type: NotificationActions;
  guideId?: string;
  pageId?: string;
}> = ({ type, guideId, pageId }) => {
  const history = useHistory();
  const { translate } = useTranslate();
  const setActivePageId = useGuideStore(s => s.setActivePageId);

  switch (type) {
    case NotificationActions.visitGuide:
      return (
        <Button
          variant="clean"
          onClick={() => {
            setActivePageId(null);
            history.push(ROUTES.GUIDE(guideId));
          }}
        >
          <Translate id="NOTIFICATION.VISIT_GUIDE_BUTTON_TEXT" />
        </Button>
      );
    case NotificationActions.visitPage:
      return (
        <Button
          variant="clean"
          onClick={() => history.push(ROUTES.GUIDE_PAGE_EDIT(guideId, pageId))}
        >
          {translate('NOTIFICATION.VISIT_PAGE_BUTTON_TEXT')}
        </Button>
      );
    case NotificationActions.reload:
    default:
      return (
        <Button variant="clean" onClick={() => window.location.reload()}>
          {translate('NOTIFICATION.REFRESH_BUTTON_TEXT')}
        </Button>
      );
  }
};

export const OutdatedGuideReason = 'Invalid aggregate version';

export const TempErrorNotification: NotificationInterface = {
  title: <Translate id="NOTIFICATION.TEMPORARY_ERROR_MESSAGE" />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.red500}
      icon={<IconError size="24" />}
      iconColor={token.white}
    />
  ),
  isPersistent: false,
};

export const BadVideoNotification: NotificationInterface = {
  title: <Translate id="NOTIFICATION.BAD_VIDEO_MESSAGE" />,
  button: <NotificationButton type={NotificationActions.reload} />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.red500}
      icon={<IconError size="24" />}
      iconColor={token.white}
    />
  ),
  isPersistent: true,
};

export const SubscriptionTimedOutNotification: NotificationInterface = {
  title: <Translate id="NOTIFICATION.SUBSCRIPTION_TIMED_OUT_MESSAGE" />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.yellow500}
      icon={<IconWarning size="24" />}
      iconColor={token.white}
    />
  ),
  button: <NotificationButton type={NotificationActions.reload} />,
  isPersistent: true,
};

export const ConfirmationTimeoutNotification: NotificationInterface = {
  title: <Translate id="NOTIFICATION.CONFIRMATION_TIMED_OUT_MESSAGE" />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.yellow500}
      icon={<IconWarning size="24" />}
      iconColor={token.white}
    />
  ),
  button: <NotificationButton type={NotificationActions.reload} />,
  isPersistent: true,
  id: timeoutNotificationId,
};

export const DeleteGroupSuccessNotification: (
  title: string,
) => NotificationInterface = title => ({
  title: <Translate id="NOTIFICATION.GROUP_DELETED_SUCCESS" title={title} />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.gray50a}
      icon={<IconDelete size="24" />}
      iconColor={token.gray600}
    />
  ),
  isPersistent: false,
});

export const LinkCopiedToClipboardNotification: NotificationInterface = {
  title: <Translate id="NOTIFICATION.SAVE_LINK_TO_CLIPBOARD" />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.gray50a}
      icon={<IconLink size="24" />}
      iconColor={token.gray600}
    />
  ),
  isPersistent: false,
};

export const ThemesetErrorNotification: NotificationInterface = {
  description: <Translate id="TYPOGRAPHY_THEMESET.NOTIFICATION_ERROR_BODY" />,
  title: <Translate id="TYPOGRAPHY_THEMESET.NOTIFICATION_ERROR_TITLE" />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.red500}
      icon={<IconError size="24" />}
      iconColor={token.white}
    />
  ),
  button: <NotificationButton type={NotificationActions.reload} />,
  isPersistent: true,
};

export const DeleteGuideSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.DELETING_GUIDE_SUCCESS_MESSAGE" />,
  isPersistent: false,
};

export const GuideAddedToGroupSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: (
    <Translate id="NOTIFICATION.ADDING_GUIDE_TO_GROUP_SUCCESS_NOTIFICATION" />
  ),
  isPersistent: false,
};

export const GuideDeletedFromGroupSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: (
    <Translate id="NOTIFICATION.REMOVING_GUIDE_FROM_GROUP_SUCCESS_NOTIFICATION" />
  ),
  isPersistent: false,
};

export const CreateGuideSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.CREATE_GUIDE_SUCCESS_NOTIFICATION" />,
  isPersistent: false,
};

export const GroupCreatedSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.CREATING_GROUP_SUCCESS_NOTIFICATION" />,
  isPersistent: false,
};

export const GroupDeletedSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.DELETING_GROUP_SUCCESS_NOTIFICATION" />,
  isPersistent: false,
};

export const DuplicateGuideSuccessNotification = (
  guideId: string,
): NotificationInterface => ({
  variant: 'success',
  title: <Translate id="NOTIFICATION.DUPLICATING_GUIDE_SUCCESS_MESSAGE" />,
  isPersistent: false,
  button: (
    <NotificationButton
      type={NotificationActions.visitGuide}
      guideId={guideId}
    />
  ),
});

export const NoPermissionsToUploadNotification: NotificationInterface = {
  description: <Translate id="NOTIFICATION.FAILED_TO_UPLOAD_ASSET_MESSAGE" />,
  title: <Translate id="NOTIFICATION.FAILED_TO_UPLOAD_ASSET_TITLE" />,
  thumbnail: (
    <Thumbnail
      variant="clean"
      shape="circle"
      backgroundColor={token.yellow500}
      icon={<IconUpload size="24" />}
      iconColor={token.white}
    />
  ),
  isPersistent: false,
};

export const PublishPageSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.PUBLISH_PAGE_SUCCESS_MESSAGE" />,
  isPersistent: false,
};

export const UnpublishPageSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.UNPUBLISH_PAGE_SUCCESS_MESSAGE" />,
  isPersistent: false,
};

export const DuplicatingPageNotification: NotificationInterface = {
  variant: 'loading',
  title: <Translate id="NOTIFICATION.DUPLICATING_PAGE_MESSAGE" />,
  isPersistent: true,
};

export const DuplicatePageSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.DUPLICATING_PAGE_SUCCESS_MESSAGE" />,
  isPersistent: false,
};

export const DownloadingCollectionErrorNotification: NotificationInterface = {
  variant: 'error',
  title: <Translate id="NOTIFICATION.COLLECTION_DOWNLOAD_FAILURE" />,
  isPersistent: false,
};

export const DownloadingCollectionSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.COLLECTION_DOWNLOAD_SUCCESS" />,
  isPersistent: false,
};

export const DownloadingAssetInCollectionErrorNotification: NotificationInterface =
  {
    variant: 'error',
    title: <Translate id="NOTIFICATION.ASSET_IN_COLLECTION_DOWNLOAD_FAILURE" />,
    isPersistent: false,
  };
export const AssetIsNotDownloadableNotification: NotificationInterface = {
  variant: 'error',
  title: <Translate id="NOTIFICATION.ASSET_IS_NOT_DOWNLOADABLE" />,
  isPersistent: false,
};

export const DownloadingAssetInCollectionSuccessNotification: NotificationInterface =
  {
    variant: 'success',
    title: <Translate id="NOTIFICATION.ASSET_IN_COLLECTION_DOWNLOAD_SUCCESS" />,
    isPersistent: false,
  };

export const UploadedAssetIsTooBigNotification = (
  filename: string,
  allowedSize: string,
): NotificationInterface => ({
  variant: 'error',
  description: (
    <Translate
      id="NOTIFICATION.UPLOAD_ERROR_NOTIFICATION_DESCRIPTION"
      maxSize={<b>{allowedSize}</b>}
    />
  ),
  title: (
    <Translate
      id="NOTIFICATION.UPLOAD_ERROR_NOTIFICATION_TITLE"
      filename={filename}
    />
  ),
  isPersistent: false,
});

export const ImageWidgetCustomUrlSuccessNotification: NotificationInterface = {
  variant: 'success',
  title: <Translate id="NOTIFICATION.IMAGE_WIDGET_CUSTOM_URL_SUCCESS" />,
  isPersistent: false,
};

export const MovePageToGuideNotification = (
  guideId: string,
  pageId: string,
  guideTitle: string,
): NotificationInterface => ({
  variant: 'success',
  title: (
    <Translate
      id="NOTIFICATION.MOVING_PAGE_TO_GUIDE_SUCCESS_NOTIFICATION"
      guideTitle={guideTitle}
    />
  ),
  isPersistent: false,
  button: (
    <NotificationButton
      type={NotificationActions.visitPage}
      guideId={guideId}
      pageId={pageId}
    />
  ),
});

export const SearchErrorNotification = (
  refetch: () => void,
): NotificationInterface => ({
  variant: 'error',
  title: <Translate id="SEARCH.ERROR_NOTIFICATION" />,
  isPersistent: false,
  button: (
    <Button variant="clean" title="Try again" onClick={refetch}>
      <Translate id="SEARCH.ERROR_NOTIFICATION_BUTTON" />
    </Button>
  ),
});
