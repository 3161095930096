import { convertToRaw, EditorState } from 'draft-js';
import { upperFirst } from 'lodash';
import { emptyCSSObject, SpacingWidgetOptions } from '../../constants/widgets';
import assertNever from '../../helpers/assertNever';
import { ColorPalette, WIDGET_TYPE, WidgetAlignment } from '../../types';
import {
  CollectionLayoutOptions,
  CollectionWidget,
} from '../../types/widgets/collections';
import { getNewCard } from '../../mutations/widgets/card';
import { getNewButton } from '../../mutations/widgets/button';
import { CSS_CLASSES, CSS_PROPERTIES } from '../guides/Inspector/constants';

export const DEFAULT_SPACER_WIDGET_HEIGHT = 64;
export const MAX_SPACER_WIDGET_HEIGHT = 500;
export const MIN_SPACER_WIDGET_HEIGHT = 24;
export const SPACER_LOCAL_STORAGE = 'spacerWidgetHeight';
export const DEFAULT_CARD_WIDGET_CARD_SIZE = 256;
export const DEFAULT_CARD_WIDGET_ALIGNMENT = WidgetAlignment.CENTER;
export const DEFAULT_BUTTON_WIDGET_GAP = 16;
export const DEFAULT_BUTTON_WIDGET_CSS_PROPERTIES = `${CSS_PROPERTIES.GAP}:${DEFAULT_BUTTON_WIDGET_GAP}px;`;
export const DEFAULT_CARD_WIDGET_CSS_PROPERTIES = `${CSS_CLASSES.CARD}{\n${CSS_PROPERTIES.WIDTH}:${DEFAULT_CARD_WIDGET_CARD_SIZE};\n}\n${CSS_PROPERTIES.JUSTIFY_CONTENT}:${DEFAULT_CARD_WIDGET_ALIGNMENT};\n`;
export const getDefaultSpacerHeight = (): number => {
  const storedValue = JSON.parse(
    window.localStorage.getItem(SPACER_LOCAL_STORAGE),
  );

  return storedValue ? storedValue : DEFAULT_SPACER_WIDGET_HEIGHT;
};

export enum TooltipPlacement {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}

export const defaultCollectionWidgetSettings = {
  layout: CollectionLayoutOptions.GRID,
  assetsShown: 4,
  showAssetTitle: true,
  showAssetType: true,
  showAssetDownload: true,
  showCollectionDownload: true,
  allowAssetRedirection: true,
  allowCollectionRedirection: true,
  showOpenAssetInAssetBank: false,
};

function prefixKey(key: string) {
  return 'widget' + upperFirst(key);
}

export function prefixKeys(obj: { [index: string]: unknown }) {
  return Object.assign(
    {},
    ...Object.keys(obj).map(key => ({ [prefixKey(key)]: obj[key] })),
  );
}

export function getEmptyEditorState() {
  return convertToRaw(EditorState.createEmpty().getCurrentContent());
}

export function getNewWidgetByType(type: WIDGET_TYPE, widgetProps?: unknown) {
  switch (type) {
    case WIDGET_TYPE.FIGMA:
      return {
        sourceId: '',
        settings: {
          controls: true,
          footer: true,
          theme: 'light',
        },
      };
    case WIDGET_TYPE.YOUTUBE:
      return {
        sourceId: '',
        settings: {
          controls: true,
          autoplay: false,
          loop: false,
        },
      };
    case WIDGET_TYPE.CARD:
      return {
        cards: [{ ...getNewCard((widgetProps as { cardId: string }).cardId) }],
        style: {
          ...emptyCSSObject,
          size: DEFAULT_CARD_WIDGET_CARD_SIZE,
          alignment: DEFAULT_CARD_WIDGET_ALIGNMENT,
          stylesheet: DEFAULT_CARD_WIDGET_CSS_PROPERTIES,
        },
      };
    case WIDGET_TYPE.BUTTON:
      return {
        style: {
          ...emptyCSSObject,
          stylesheet: DEFAULT_BUTTON_WIDGET_CSS_PROPERTIES,
        },
        buttons: [
          { ...getNewButton((widgetProps as { buttonId: string }).buttonId) },
        ],
      };
    case WIDGET_TYPE.TITLE:
      return {
        title: '',
      };
    case WIDGET_TYPE.TEXT:
      return {
        text: '',
      };
    case WIDGET_TYPE.IMAGE:
    case WIDGET_TYPE.PERSONAL_FILE:
      return {
        assetName: '',
        assetId: '',
        imageUrl: '',
        detailUrl: '',
        assetExtension: '',
      };
    case WIDGET_TYPE.VIDEO:
      return {
        assetName: '',
        assetId: '',
        videoUrl: '',
        detailUrl: '',
        coverUrl: '',
      };
    case WIDGET_TYPE.COLOR_PALETTE:
      return {
        color: [] as ColorPalette[],
      };
    case WIDGET_TYPE.COLLECTION:
      return {
        collectionPayload: {
          style: {
            stylesheet: '',
            language: 'CSS',
            version: 3,
          },
          collectionId: '',
          collectionSettings: defaultCollectionWidgetSettings,
        } as CollectionWidget,
      };
    case WIDGET_TYPE.TABLE:
      return {
        tablePayload: {
          numRows: 3,
          numColumns: 3,
        },
      };
    case WIDGET_TYPE.AUDIO:
      return {
        audioPayload: {
          assetId: '',
          assetName: '',
          audioUrl: '',
          detailUrl: '',
        },
      };
    case WIDGET_TYPE.SPACER:
      return {
        height: getDefaultSpacerHeight(),
        divider: SpacingWidgetOptions.None,
        style: {
          stylesheet: '',
          language: 'CSS',
          version: 3,
        },
      };
    case WIDGET_TYPE.VIMEO:
      return {
        sourceId: '',
        settings: {
          autoplay: false,
          loop: false,
          detailsShown: true,
        },
      };
    default:
      // throw ts error if widget type missing
      assertNever(type);
  }
}
