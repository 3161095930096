import { BaseCommand, Cover, EntityVisibility, Permission, Section } from '.';
import { PageHeaderHeights } from './headerHeights';
import { TableOfContents } from './tableOfContents';

export enum PageStates {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface Page {
  id: string;
  title: string;
  creationTime?: string;
  lastModifiedTime?: string;
  permissions: Permission[];
  visibility: EntityVisibility;
  sections: Section[];
  isFresh: boolean;
  state: PageStates;
  widgetCount: number;
  canEdit: boolean;
  ownerId?: string;
  canView: boolean;
  header?: PageHeaderOptions;
  tableOfContents: TableOfContents;
}

export interface PageHeaderOptions {
  asset: Cover;
  height: PageHeaderHeights;
  backgroundColor: string;
  textColorId: string;
  isTitleVisible: boolean;
  isSubtitleVisible: boolean;
  isVisible: boolean;
}

export type AddPageToChapterCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  pageTitle: string;
};

export type EditPageTitleCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  pageTitle: string;
};

export type DeletePageCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
};

export type MovePageCommand = BaseCommand & {
  pageId: string;
  from: {
    chapterId: string;
  };
  to: {
    chapterId: string;
    position: number;
  };
};

export type PublishPageCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
};

export type SetPageCoverCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  pageCover: Cover;
};

export type RemoveAssetFromPageHeaderCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
};

export type editHeightInPageHeaderCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  pageHeaderHeight: PageHeaderHeights;
};

export type EditBackgroundColorInPageHeaderCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  pageHeaderBackgroundColorId: string;
};
export type EditTextColorInPageHeaderCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  pageHeaderTextColorId: string;
};

export type EditTableOfContentsCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  isVisibleInPage: boolean;
  isVisibleInGuide: boolean;
};

export type DuplicatePageInChapterCommand = BaseCommand & {
  chapterId: string;
  pageId: string;
  newPagePayload: NewPagePayload;
};

export type NewPagePayload = {
  pageId: string;
  pagePosition: number;
  pageTitle: string;
};

export type PageCommands =
  | AddPageToChapterCommand
  | EditPageTitleCommand
  | DeletePageCommand
  | MovePageCommand;
