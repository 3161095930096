import { useQuery } from '@tanstack/react-query';
import { retrieveFeatureTreatment } from './api/featureTreatmentApi';
import { UserType, useUserType } from './hooks';

export enum ActiveSplits {
  landingPage = 'guidelines_landing_page',
  buttonWidget = 'brandguidelines_button_widget',
  pageHeader = 'guidelines_page_header_toggle',
  q4Features = 'brandguidelines_q4_features',
  pageInPage = 'brandguidelines_page_in_page_poc',
}

export const useReleaseToggle = (splitName: ActiveSplits) => {
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [splitName, isPublic],
    () => retrieveFeatureTreatment(splitName, isPublic),
    {
      select: data => data === 'on',
    },
  );
};
