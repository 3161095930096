import { includes, pull } from 'lodash';
import { v4 } from 'uuid';
import { InfiniteData } from '@tanstack/react-query';
import { SearchResultResponse } from '../components/search/types';
import { Guide } from '../types';

interface ObjectWithId {
  id: string;
}

export const generateUUID = () => v4();

const separator = '_';

export const joinIds = (...ids: string[]) => ids.join(separator);

export const splitIds = (ids: string) => ids.split(separator);

export const hasId = <T extends ObjectWithId>(array: T[], id: string) =>
  array.find((f: T) => f.id === id);

export const mapIds = (array: Array<{ id: string }>) => array.map(a => a.id);

export const copyToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

export const insertAtIndex = <T>(array: T[], index: number, value: T) => {
  return [...array.slice(0, index), value, ...array.slice(index)];
};

export const removeAtIndex = <T>(array: T[], index: number) =>
  array.filter((_e, i) => index !== i);

export const cloneArray = <T>(array: T[]) => [...array];

export const swapArrayPositions = <T>(array: T[], from: number, to: number) => {
  if (array[to] === undefined || array[from] === undefined) {
    return array;
  }

  const element = array[from];
  array.splice(from, 1);
  array.splice(to, 0, element);

  return array;
};

export const generateRandomTextBlockKey = (): string => {
  const MULTIPLIER = Math.pow(2, 24);
  let key;

  while (key === undefined || !isNaN(+key)) {
    key = Math.floor(Math.random() * MULTIPLIER).toString(32);
  }

  return key;
};

export const getRecentSearches = (key: string): string[] => {
  return JSON.parse(localStorage.getItem(key)) || [];
};

export const setRecentSearchItem = (key: string, item: string) => {
  if (!item.length) return;

  const array = getRecentSearches(key);

  if (includes(array, item)) {
    pull(array, item);
  }

  array.push(item);

  localStorage.setItem(key, JSON.stringify(array));
};

export const getTotalNumberOfElements = (
  data: InfiniteData<SearchResultResponse>,
) => {
  if (!data) return;

  let numberOfElements = 0;

  for (const page of data.pages) {
    numberOfElements += page?.pageInfo?.numberOfElements;
  }

  return numberOfElements;
};

export const svgtob64 = (icon: string) =>
  'data:image/svg+xml;base64,' + window.btoa(icon);

export const filterOutGroupGuides = (guideArray: Guide[]) =>
  guideArray?.filter(guide => !guide.groupId);

export const isValidUrl = (urlStr: string) => {
  let url: URL;

  try {
    url = new URL(urlStr);
  } catch (e) {
    return false;
  }

  return (
    url.protocol === 'http:' || 
    url.protocol === 'https:' || 
    url.protocol === 'mailto:'
  );
};
