import { isDeimosStage, isDevelopment } from '../app';

export enum SOCKETS {
  JOIN_ROOM = 'JOIN_ROOM',
  LEAVE_ROOM = 'LEAVE_ROOM',
  LISTEN_TO_SOCKET = 'LISTEN_TO_SOCKET',
  WAIT_FOR_MESSAGE = 'WAIT_FOR_MESSAGE',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_PROCESSED = 'MESSAGE_PROCESSED',
  INIT_SOCKET_COMPLETED = 'INIT_SOCKET_COMPLETED',
  // BG events
  COMMAND_FAILED = 'COMMAND_FAILED',
  COMMAND_FAILED_AGGREGATE_SIZE_LIMIT = 'COMMAND_FAILED_AGGREGATE_SIZE_LIMIT',
  GUIDE_CREATED = 'GUIDE_CREATED',
  GUIDE_DELETED = 'GUIDE_DELETED',
  GUIDE_TITLE_EDITED = 'GUIDE_TITLE_EDITED',
  NEW_CHAPTER_ADDED_TO_GUIDE = 'NEW_CHAPTER_ADDED_TO_GUIDE',
  CHAPTER_TITLE_EDITED = 'CHAPTER_TITLE_EDITED',
  NEW_PAGE_ADDED_TO_CHAPTER = 'NEW_PAGE_ADDED_TO_CHAPTER',
  PAGE_TITLE_EDITED = 'PAGE_TITLE_EDITED',
  NEW_SECTION_ADDED_TO_PAGE = 'NEW_SECTION_ADDED_TO_PAGE',
  SECTION_DELETED_FROM_PAGE = 'SECTION_DELETED_FROM_PAGE',
  NUMBER_OF_COLUMNS_CHANGED_IN_SECTION = 'NUMBER_OF_COLUMNS_CHANGED_IN_SECTION',
  WIDGET_MOVED = 'WIDGET_MOVED',
  NEW_TITLE_WIDGET_ADDED_TO_SECTION = 'NEW_TITLE_WIDGET_ADDED_TO_SECTION',
  TITLE_EDITED_IN_TITLE_WIDGET = 'TITLE_EDITED_IN_TITLE_WIDGET',
  NEW_TEXT_WIDGET_ADDED_TO_SECTION = 'NEW_TEXT_WIDGET_ADDED_TO_SECTION',
  TEXT_EDITED_IN_TEXT_WIDGET = 'TEXT_EDITED_IN_TEXT_WIDGET',
  CHAPTER_DELETED_FROM_GUIDE = 'CHAPTER_DELETED_FROM_GUIDE',
  NEW_IMAGE_WIDGET_ADDED_TO_SECTION = 'NEW_IMAGE_WIDGET_ADDED_TO_SECTION',
  ASSET_PLACED_IN_IMAGE_WIDGET = 'ASSET_PLACED_IN_IMAGE_WIDGET',
  PAGE_DELETED_FROM_CHAPTER = 'PAGE_DELETED_FROM_CHAPTER',
  WIDGET_DELETED_FROM_SECTION = 'WIDGET_DELETED_FROM_SECTION',
  GUIDE_COVER_SET = 'GUIDE_COVER_SET',
  GUIDE_COVER_REMOVED = 'GUIDE_COVER_REMOVED',
  PAGE_PUBLISHED = 'PAGE_PUBLISHED',
  PAGE_UNPUBLISHED = 'PAGE_UNPUBLISHED',
  ASSET_PLACED_IN_PAGE_HEADER = 'ASSET_PLACED_IN_PAGE_HEADER',
  ASSET_REMOVED_FROM_PAGE_HEADER = 'ASSET_REMOVED_FROM_PAGE_HEADER',
  HEIGHT_EDITED_IN_PAGE_HEADER = 'HEIGHT_EDITED_IN_PAGE_HEADER',
  BACKGROUND_COLOR_EDITED_IN_PAGE_HEADER = 'BACKGROUND_COLOR_EDITED_IN_PAGE_HEADER',
  TEXT_COLOR_EDITED_IN_PAGE_HEADER = 'TEXT_COLOR_EDITED_IN_PAGE_HEADER',

  NEW_VIDEO_WIDGET_ADDED_TO_SECTION = 'NEW_VIDEO_WIDGET_ADDED_TO_SECTION',
  ASSET_PLACED_IN_VIDEO_WIDGET = 'ASSET_PLACED_IN_VIDEO_WIDGET',
  ALL_PORTAL_USERS_ALLOWED_TO_VIEW_GUIDE = 'ALL_PORTAL_USERS_ALLOWED_TO_VIEW_GUIDE',
  NEW_TABLE_WIDGET_ADDED_TO_SECTION = 'NEW_TABLE_WIDGET_ADDED_TO_SECTION',
  CELLS_CONTENT_EDITED_IN_TABLE_WIDGET = 'CELLS_CONTENT_EDITED_IN_TABLE_WIDGET',
  COLUMN_INSERTED_IN_TABLE_WIDGET = 'COLUMN_INSERTED_IN_TABLE_WIDGET',
  COLUMN_REMOVED_FROM_TABLE_WIDGET = 'COLUMN_REMOVED_FROM_TABLE_WIDGET',
  ROW_INSERTED_IN_TABLE_WIDGET = 'ROW_INSERTED_IN_TABLE_WIDGET',
  ROW_REMOVED_FROM_TABLE_WIDGET = 'ROW_REMOVED_FROM_TABLE_WIDGET',
  NEW_AUDIO_WIDGET_ADDED_TO_SECTION = 'NEW_AUDIO_WIDGET_ADDED_TO_SECTION',
  ASSET_PLACED_IN_AUDIO_WIDGET = 'ASSET_PLACED_IN_AUDIO_WIDGET',
  SECTION_MOVED = 'SECTION_MOVED',
  SECTION_DUPLICATED_IN_PAGE = 'SECTION_DUPLICATED_IN_PAGE',
  BACKGROUND_COLOR_EDITED_IN_SECTION = 'BACKGROUND_COLOR_EDITED_IN_SECTION',
  GROUP_CREATED = 'GROUP_CREATED',
  GROUP_TITLE_EDITED = 'GROUP_TITLE_EDITED',
  GROUP_DESCRIPTION_EDITED = 'GROUP_DESCRIPTION_EDITED',
  GROUP_DELETED = 'GROUP_DELETED',
  GROUP_COVER_ASSET_EDITED = 'GROUP_COVER_ASSET_EDITED',
  GROUP_COVER_COLOR_EDITED = 'GROUP_COVER_COLOR_EDITED',
  IMAGE_ACTION_SET_TO_OPEN_PREVIEW = 'IMAGE_ACTION_SET_TO_OPEN_PREVIEW',
  IMAGE_ACTION_SET_TO_NO_ACTION = 'IMAGE_ACTION_SET_TO_NO_ACTION',
  IMAGE_ACTION_SET_TO_LINK_TO_URL = 'IMAGE_ACTION_SET_TO_LINK_TO_URL',
  GUIDE_ADDED_TO_GROUP = 'GUIDE_ADDED_TO_GROUP',
  GUIDE_DELETED_FROM_GROUP = 'GUIDE_DELETED_FROM_GROUP',
  CHAPTER_MOVED = 'CHAPTER_MOVED',
  PAGE_MOVED = 'PAGE_MOVED',
  GUIDE_MADE_PUBLIC = 'GUIDE_MADE_PUBLIC',
  GUIDE_FEATURED = 'GUIDE_FEATURED',
  GUIDE_UNFEATURED = 'GUIDE_UNFEATURED',
  SECTION_LAYOUT_CHANGED = 'SECTION_LAYOUT_CHANGED',
  TABLE_OF_CONTENTS_EDITED = 'TABLE_OF_CONTENTS_EDITED',
  GUIDE_CREATED_INSIDE_GROUP = 'GUIDE_CREATED_INSIDE_GROUP',
  UPDATE_GUIDE_SAVING_STATUS = 'UPDATE_GUIDE_SAVING_STATUS',
  GUIDE_ACCESS_EDITED = 'GUIDE_ACCESS_EDITED',
  GROUP_ACCESS_EDITED = 'GROUP_ACCESS_EDITED',
  PAGE_ACCESS_EDITED = 'PAGE_ACCESS_EDITED',
  CHAPTER_ACCESS_EDITED = 'CHAPTER_ACCESS_EDITED',
  PAGE_DUPLICATED_IN_CHAPTER = 'PAGE_DUPLICATED_IN_CHAPTER',
  GUIDE_CREATED_WITH_TEMPLATE = 'GUIDE_CREATED_WITH_TEMPLATE',
  GUIDE_CREATED_WITH_TEMPLATE_INSIDE_GROUP = 'GUIDE_CREATED_WITH_TEMPLATE_INSIDE_GROUP',
  GUIDE_DUPLICATED = 'GUIDE_DUPLICATED',
  GUIDE_DUPLICATED_INSIDE_GROUP = 'GUIDE_DUPLICATED_INSIDE_GROUP',
  THEMESET_EDITED = 'THEMESET_EDITED',
  THEMESET_CREATED = 'THEMESET_CREATED',
  THEMESET_DELETED = 'THEMESET_DELETED',
  THEMESET_ASSIGNED = 'THEMESET_ASSIGNED',
  GUIDE_DELETED_INSIDE_GROUP = 'GUIDE_DELETED_INSIDE_GROUP',
  SECTION_WIDTH_EDITED = 'SECTION_WIDTH_EDITED',
  SECTION_HEIGHT_EDITED = 'SECTION_HEIGHT_EDITED',
  SECTION_CONTENT_ALIGNMENT_EDITED = 'SECTION_CONTENT_ALIGNMENT_EDITED',
  SECTION_VERTICAL_PADDING_EDITED = 'SECTION_VERTICAL_PADDING_EDITED',
  SECTION_HORIZONTAL_PADDING_EDITED = 'SECTION_HORIZONTAL_PADDING_EDITED',
  SECTION_CONTENT_SPACING_EDITED = 'SECTION_CONTENT_SPACING_EDITED',
  TITLE_VISIBILITY_EDITED_IN_PAGE_HEADER = 'TITLE_VISIBILITY_EDITED_IN_PAGE_HEADER',
  SUBTITLE_VISIBILITY_EDITED_IN_PAGE_HEADER = 'SUBTITLE_VISIBILITY_EDITED_IN_PAGE_HEADER',
  SECTION_COLUMN_SPACING_EDITED = 'SECTION_COLUMN_SPACING_EDITED',
  CONTROLS_EDITED_IN_VIDEO_WIDGET = 'CONTROLS_EDITED_IN_VIDEO_WIDGET',
  MUTED_EDITED_IN_VIDEO_WIDGET = 'MUTED_EDITED_IN_VIDEO_WIDGET',
  AUTOPLAY_EDITED_IN_VIDEO_WIDGET = 'AUTOPLAY_EDITED_IN_VIDEO_WIDGET',
  LOOP_EDITED_IN_VIDEO_WIDGET = 'LOOP_EDITED_IN_VIDEO_WIDGET',
  HEADER_VISIBILITY_EDITED_IN_PAGE_HEADER = 'HEADER_VISIBILITY_EDITED_IN_PAGE_HEADER',
  ASSET_PLACED_IN_SECTION = 'ASSET_PLACED_IN_SECTION',
  ASSET_REMOVED_FROM_SECTION = 'ASSET_REMOVED_FROM_SECTION',
  STYLE_EDITED_IN_SECTION = 'STYLE_EDITED_IN_SECTION',
  // CARD WIDGET
  NEW_CARD_WIDGET_ADDED_TO_SECTION = 'NEW_CARD_WIDGET_ADDED_TO_SECTION',
  NEW_CARDS_ADDED_TO_CARD_WIDGET = 'NEW_CARDS_ADDED_TO_CARD_WIDGET',
  CARD_REMOVED_FROM_CARD_WIDGET = 'CARD_REMOVED_FROM_CARD_WIDGET',
  CARD_MOVED_IN_CARD_WIDGET = 'CARD_MOVED_IN_CARD_WIDGET',
  CARD_ASSET_EDITED_IN_CARD_WIDGET = 'CARD_ASSET_EDITED_IN_CARD_WIDGET',
  CARD_HEADLINE_EDITED_IN_CARD_WIDGET = 'CARD_HEADLINE_EDITED_IN_CARD_WIDGET',
  CARD_LINK_EDITED_IN_CARD_WIDGET = 'CARD_LINK_EDITED_IN_CARD_WIDGET',
  STYLE_EDITED_IN_CARD_WIDGET = 'STYLE_EDITED_IN_CARD_WIDGET',
  WIDGET_STYLE_EDITED_IN_CARD_WIDGET = 'WIDGET_STYLE_EDITED_IN_CARD_WIDGET',
  // BUTTON WIDGET
  NEW_BUTTONS_ADDED_TO_BUTTON_WIDGET = 'NEW_BUTTONS_ADDED_TO_BUTTON_WIDGET',
  NEW_BUTTON_WIDGET_ADDED_TO_SECTION = 'NEW_BUTTON_WIDGET_ADDED_TO_SECTION',
  NAME_EDITED_IN_BUTTON_WIDGET = 'NAME_EDITED_IN_BUTTON_WIDGET',
  LINK_EDITED_IN_BUTTON_WIDGET = 'LINK_EDITED_IN_BUTTON_WIDGET',
  STYLE_EDITED_IN_BUTTON_WIDGET = 'STYLE_EDITED_IN_BUTTON_WIDGET',
  WIDGET_STYLE_EDITED_IN_BUTTON_WIDGET = 'WIDGET_STYLE_EDITED_IN_BUTTON_WIDGET',
  BUTTON_REMOVED_FROM_BUTTON_WIDGET = 'BUTTON_REMOVED_FROM_BUTTON_WIDGET',
  // SPACER WIDGET
  NEW_SPACING_WIDGET_ADDED_TO_SECTION = 'NEW_SPACING_WIDGET_ADDED_TO_SECTION',
  HEIGHT_EDITED_IN_SPACING_WIDGET = 'HEIGHT_EDITED_IN_SPACING_WIDGET',
  DIVIDER_EDITED_IN_SPACING_WIDGET = 'DIVIDER_EDITED_IN_SPACING_WIDGET',
  STYLE_EDITED_IN_SPACING_WIDGET = 'STYLE_EDITED_IN_SPACING_WIDGET',
  GUIDE_BATCH_EXECUTED = 'GUIDE_BATCH_EXECUTED',
  //YOUTUBE WIDGET
  SOURCE_EDITED_IN_YOUTUBE_WIDGET = 'SOURCE_EDITED_IN_YOUTUBE_WIDGET',
  SETTINGS_EDITED_IN_YOUTUBE_WIDGET = 'SETTINGS_EDITED_IN_YOUTUBE_WIDGET',
  NEW_YOUTUBE_WIDGET_ADDED_TO_SECTION = 'NEW_YOUTUBE_WIDGET_ADDED_TO_SECTION',
  // VIMEO WIDGET
  SOURCE_EDITED_IN_VIMEO_WIDGET = 'SOURCE_EDITED_IN_VIMEO_WIDGET',
  SETTINGS_EDITED_IN_VIMEO_WIDGET = 'SETTINGS_EDITED_IN_VIMEO_WIDGET',
  NEW_VIMEO_WIDGET_ADDED_TO_SECTION = 'NEW_VIMEO_WIDGET_ADDED_TO_SECTION',
  // COLOR PALETTE WIDGET
  NEW_COLOR_PALETTE_WIDGET_ADDED_TO_SECTION = 'NEW_COLOR_PALETTE_WIDGET_ADDED_TO_SECTION',
  NEW_COLOR_ADDED_TO_COLOR_PALETTE_WIDGET = 'NEW_COLOR_ADDED_TO_COLOR_PALETTE_WIDGET',
  COLOR_REMOVED_FROM_COLOR_PALETTE_WIDGET = 'COLOR_REMOVED_FROM_COLOR_PALETTE_WIDGET',
  COLOR_EDITED_IN_COLOR_PALETTE_WIDGET = 'COLOR_EDITED_IN_COLOR_PALETTE_WIDGET',
  STYLE_EDITED_IN_COLOR_PALETTE_WIDGET = 'STYLE_EDITED_IN_COLOR_PALETTE_WIDGET',
  COLOR_NAME_EDITED_IN_COLOR_PALETTE_WIDGET = 'COLOR_NAME_EDITED_IN_COLOR_PALETTE_WIDGET',
  COLOR_HEX_CODE_EDITED_IN_COLOR_PALETTE_WIDGET = 'COLOR_HEX_CODE_EDITED_IN_COLOR_PALETTE_WIDGET',
  CUSTOM_COLOR_EDITED_IN_COLOR_PALETTE_WIDGET = 'CUSTOM_COLOR_EDITED_IN_COLOR_PALETTE_WIDGET',
  DEFAULT_COLOR_CODES_SET_IN_COLOR_PALETTE_WIDGET = 'DEFAULT_COLOR_CODES_SET_IN_COLOR_PALETTE_WIDGET',
  // COLLECTION WIDGET
  STYLE_EDITED_IN_COLLECTION_WIDGET = 'STYLE_EDITED_IN_COLLECTION_WIDGET',
  NEW_COLLECTION_WIDGET_ADDED_TO_SECTION = 'NEW_COLLECTION_WIDGET_ADDED_TO_SECTION',
  COLLECTION_PLACED_IN_COLLECTION_WIDGET = 'COLLECTION_PLACED_IN_COLLECTION_WIDGET',
  COLLECTION_WIDGET_EDITED = 'COLLECTION_WIDGET_EDITED',
  ASSETS_SHOWN_CHANGED_IN_COLLECTION_WIDGET = 'ASSETS_SHOWN_CHANGED_IN_COLLECTION_WIDGET',
  // FIGMA WIDGET
  SOURCE_EDITED_IN_FIGMA_WIDGET = 'SOURCE_EDITED_IN_FIGMA_WIDGET',
  SETTINGS_EDITED_IN_FIGMA_WIDGET = 'SETTINGS_EDITED_IN_FIGMA_WIDGET',
  NEW_FIGMA_WIDGET_ADDED_TO_SECTION = 'NEW_FIGMA_WIDGET_ADDED_TO_SECTION',

}

export const WS_ENDPOINT = {
  DEV: '/socket.io.v2',
  STAGE: '/v7/socket.io.v2',
  PROD: '/v7/socket.io.v2',
};

export const getWSEndpoint = () => {
  if (isDevelopment) return WS_ENDPOINT.DEV;
  else if (isDeimosStage) return WS_ENDPOINT.STAGE;

  return WS_ENDPOINT.PROD;
};

export default SOCKETS;
